import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import Login from '@/views/Login.vue'
import Logout from '@/views/Logout.vue'
import AppMain from '@/views/AppMain.vue'
import SearchResult from '@/views/SearchResult.vue'
import NetworkVis from '@/views/NetworkVis.vue'
import { checkLoggedIn } from '@/gateway/api/auth'
import { userStore } from '@/store/user'
import firebase from 'firebase/app'
import LP from '@/views/LP.vue'
import Top from '@/views/Top.vue'
import SignUp from '@/views/SignUp.vue'
import Account from '@/views/Account.vue'
import PasswordChange from '@/views/PasswordChange.vue'

Vue.use(VueRouter)

const ifNotAuthenticated = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
) => {
  checkLoggedIn()
    .then((user) => {
      userStore.setUser(user)
      next('/app')
    })
    .catch(() => {
      next()
    })
}

const ifAuthenticated = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
) => {
  if (userStore.getIsLoggedIn) {
    next()
  }

  checkLoggedIn()
    .then((user) => {
      userStore.setUser(user)
      next()
    })
    .catch(() => {
      next('/app/login')
    })
}

const checkAuthentication = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
) => {
  if (userStore.getIsLoggedIn) {
    next()
  }

  checkLoggedIn()
    .then((user) => {
      userStore.setUser(user)
    })
    .finally(() => {
      next()
    })
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'LP',
    component: LP,
    children: [],
  },
  {
    path: '/app',
    name: 'AppMain',
    component: AppMain,
    children: [
      {
        path: '/',
        name: 'Top',
        component: Top,
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'account/',
        name: 'Account',
        component: Account,
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'password/change/',
        name: 'PasswordChange',
        component: PasswordChange,
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'search/',
        name: 'SearchResult',
        component: SearchResult,
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'network/',
        name: 'NetworkVis',
        component: NetworkVis,
        beforeEnter: ifAuthenticated,
      },
      {
        path: 'signup/',
        name: 'SignUp',
        component: SignUp,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: 'login/',
        name: 'Login',
        component: Login,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: 'logout/',
        name: 'Logout',
        component: Logout,
        beforeEnter: checkAuthentication,
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const logPageLocation = (to: Route, from: Route) => {
  firebase.analytics().logEvent('custom_page_view')
}
router.afterEach(logPageLocation)

export default router
